<template>
  <div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column label="柜子名称" prop="stationName"></el-table-column>

      <el-table-column label="柜子编号" prop="stationMacId"></el-table-column>
      
      <el-table-column label="柜子电池" prop="batteryMacId"></el-table-column>

      <el-table-column label="放入电池" prop="backBatteryMacId"></el-table-column>

      <el-table-column label="用户" prop="userRealName"></el-table-column>
      <el-table-column label="仓门" prop="doorId"> </el-table-column>
      <el-table-column label="用户类型" prop="userType">
        <template #default="scope">
          <eb-badge
            :list="this.const.USER_TYPE"
            :target="scope.row.userType"
          ></eb-badge>
        </template>
      </el-table-column>
      <el-table-column label="开门时间" prop="openTime"> </el-table-column>
      <el-table-column label="关门时间" prop="closeTime"></el-table-column>
      <el-table-column label="状态" prop="type">
        <template #default="scope">
          <eb-badge
            :list="this.const.OPEN_DOOR_TYPE"
            :target="scope.row.type"
          ></eb-badge>
        </template>
      </el-table-column>
      <el-table-column label="信息" prop="info">
        <template #default="scope">
          <span>{{ scope.row.info }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="状态" prop="type">
        <template #default="scope">
          <eb-badge
            :list="this.const.OPEN_DOOR_TYPE"
            :target="scope.row.type"
          ></eb-badge>
        </template>
      </el-table-column> -->
    </el-table>

    <el-pagination
      v-if="pageable"
      layout="total,prev, pager, next,jumper"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>

<script>
import { toRefs, toRef, reactive } from "vue";
import { validatenull } from "@/common/validate";
import { fetchOpenDoorList } from "@/api/order";
import { useRouter } from "vue-router";

export default {
  props: ["listQuery", "pageable"],

  setup(props, context) {
    let listQuery = toRef(props, "listQuery");
    let router = useRouter();
    const state = reactive({
      total: 0,
      dataSource: [],
      modalData: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      listQuery: listQuery,
    });

    const goDetail = (id) => {
      router.push("/user/detail/" + id);
    };
    const getList = (current) => {
      if (!validatenull(current)) {
        state.pages.pageIndex = current;
      }

      fetchOpenDoorList({ ...state.listQuery, ...state.pages }).then(
        (res) => {
          let list = res.data.data.records || [];
          state.total = res.data.data.total;
          state.dataSource = list;
        }
      );
    };

    return {
      ...toRefs(state),
      listQuery: listQuery.value,
      getList,
      goDetail,
    };
  },
};
</script>
<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="10">
        <eb-date-range v-model="dateRange" @change="changeDate"></eb-date-range>
      </el-col>

      <el-col :span="4">
        <el-select
          v-model="query.userType"
          placeholder="开门类型"
          clearable
          filterable
        >
          <el-option label="代理开门" :value="3"></el-option>

          <el-option label="管理员开门" :value="2"></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-input placeholder="开门信息" v-model="query.info"></el-input>
      </el-col>

      <el-col :span="4">
        <el-input
          placeholder="电池编号"
          v-model="query.batteryMacId"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-select
          v-model="query.stationMacId"
          placeholder="站点"
          clearable
          filterable
        >
          <template v-for="(item, index) in stationList.list">
            <el-option
              v-if="item.stationMacid != ''"
              :label="item.stationName"
              :value="item.stationMacId"
            ></el-option>
          </template>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-input v-model="query.userRealName" placeholder="用户姓名"> </el-input>
      </el-col>

      <el-col :span="4">
        <el-input v-model="query.doorId" placeholder="仓门"> </el-input>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        label="代理商名称"
        prop="operationName" 
      ></el-table-column>

      <el-table-column label="地址" prop="address"></el-table-column>

      <el-table-column label="充值比例" prop="chargeScale"></el-table-column>

      <el-table-column label="用户押金" prop="depositScale"></el-table-column>

      <el-table-column label="联系方式" prop="phone"></el-table-column>

      <el-table-column label="押金" prop="deposit"></el-table-column>

      <el-table-column label="已冻结押金" prop="freezeDeposit"></el-table-column>
    </el-table>

    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
   
  </div>
</template>

<script>
import OpenDoorRecord from "@/components/view-components/open-door-record";
import { mapState } from "vuex";
import moment from "moment";
import { fetchBicyclePage } from "@/api/bicycle";

export default {
  components: { OpenDoorRecord },
  computed: mapState({
    stationList: (state) => {
      return state.station.stationList || { list: [], map: {} };
    },
  }),
  data() {
    let startTime = moment().startOf("M").format("YYYY-MM-DD HH:mm:ss");
    console.log(startTime);
    let endTime = moment().endOf("M").format("YYYY-MM-DD HH:mm:ss");
    return {
      dateRange: [startTime, endTime],
      stationName: [],
      dataSource: [],
      modalData: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {
        info: "",
        startTime: startTime,
        endTime: endTime,
      },
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    changeDate(e) {
      if (!this.validatenull(e)) {
        this.query.startTime = moment(e[0]).format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = moment(e[1]).format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
    },

    getList(current) {
        if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchBicyclePage({ ...this.query, ...this.pages }).then((res) => {
        let list = res.data.data.records || [];
        this.total = res.data.data.total;
        this.dataSource = list;
      });
    },
  },
};
</script>
